@font-face{
    font-family: 'Inter';
    src: url("./font/Inter-Regular.ttf");
}
@font-face{
    font-family: 'Inter-sb';
    src: url("./font/Inter-SemiBold.ttf");
}

body{
    font-family: "Inter";
}